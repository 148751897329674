<script lang="ts" setup>
import CategoriesList from './categories-list.vue'
import CategoryDetails from './category-details.vue'
const Route = useRoute()

const showList = computed(() => {
    return (
        /^(popular|a|b|c|d|e|f|g|h|i|j|k|l|m|n|ñ|o|p|q|r|s|t|u|v|w|x|y|z)$/.test(
            Route.params.slug as string,
        ) || !Route.params.slug
    )
})
</script>

<template>
    <CategoryDetails v-if="!showList"></CategoryDetails>
    <CategoriesList v-else></CategoriesList>
</template>
